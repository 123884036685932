<template>
    <div style="width: 1500px;overflow: hidden;min-height: 800px;margin: 0 auto;padding-top: 110px;">
        <!--   左侧分类  -->
        <div style="width: 290px;background: #FFFFFF;min-height: 720px;border-radius: 5px;margin-top: 5px;float: left">
            <el-menu :default-active="selected"  style="width:100%; overflow: hidden;" @select="selectedswitch" unique-opened>
                <el-menu-item :index="item.type" style="height: 46px; line-height: 42px; margin-bottom: 2px"
                              v-for="(item,index) in aboutuslist" v-bind:key="index"  @click="selectedswitch(item.type,item.name)"
                >
                    <template>
                        <div style="display: flex;justify-content:center;">
                            <span style="font-size: 16px; margin-top: 2px;text-align: center">{{ item.name }}</span>
                            <div style="width: 20px; height: 20px; margin-left: 10px;" v-if="selected == item.type">
                                <i class="el-icon-caret-right" style="color: #2970FF"></i>
                            </div>
                        </div>
                    </template>
                </el-menu-item>
            </el-menu>
        </div>
        <!--   右侧列表  -->
        <div style="width: 1200px;overflow: hidden;float: left;margin-left: 10px;background: #FFFFFF;min-height: 720px;margin-top: 5px;">

        </div>
    </div>
</template>

<script>

export default {
    name: "AboutUs",
    data() {
        return {
            aboutuslist:[
                {name:"公司资质",type:"1"},
                {name:"版权声明",type:"2"},
                {name:"支付方式",type:"3"},
                {name:"隐私政策",type:"4"},
                {name:"联系我们",type:"5"},
            ],
            selected:this.$route.params.type,
            type_name:''
        }
    },
    computed: {

    },
    created: function () {

    },
    mounted: function () {
        let that = this;
        if(that.$route.params.type == 1){
            that.type_name="公司资质"
        }else if(that.$route.params.type == 2){
            that.type_name="版权声明"
        }else if(that.$route.params.type == 3){
            that.type_name="支付方式"
        }else if (that.$route.params.type == 4){
            that.type_name="隐私政策"
        }else if (that.$route.params.type == 5){
            that.type_name="联系我们"
        }
        document.title = that.type_name;
    },
    methods: {
        selectedswitch(type,name){
            this.selected = type;
            document.title = name;
        },
    }
}
</script>

<style scoped>

</style>